/* Introduce Section */
.introduceSection {
  padding: 68px 0;
  position: relative;
  overflow: hidden;
}
.introduceSection .introduceContent {
  position: relative;
  z-index: 3;
}
.introduceSection .introduceContent h2 {
  font-size: 55px;
  letter-spacing: -0.2px;
  line-height: 90px;
  margin-bottom: 43px;
  font-weight: 300;
}
.introduceSection .introduceContent h2 span {
  color: var(--primary_color);
}
.introduceSection .introduceContent > p {
  /* max-width: 480px; */
  max-width: 615px;
  margin-bottom: 31px;
}
.introduceSection .introduceContent .moveToProjectBtn {
  width: 175px;
  height: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  border-radius: 50%;
  border: 1px solid #575757;
  position: relative;
  overflow: hidden;
  margin-right: 15px;
}
.introduceSection .introduceContent .moveToProjectBtn img {
  display: block;
  animation: rotating 6s infinite linear;
}
.introduceSection .introduceContent .moveToProjectBtn i {
  position: absolute;
  color: #fff;
  font-size: 40px;
  display: block;
}
.introduceSection .introduceContent .facts {
  gap: 100px;
  margin-top: 55px;
}
.introduceSection .introduceContent .facts h2 {
  font-size: 72px;
  color: var(--primary_color);
  line-height: 56px;
  margin-bottom: 38px;
}
.introduceSection .introduceContent .facts p {
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
}
