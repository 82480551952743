.sectionOvelay {
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gameSwitchMode {
  margin-top: 20px;
}

.gameSwitchMode > span {
  font-size: smaller;
}
