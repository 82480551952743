.blackIcon {
  color: black;
}

.whiteIcon {
  color: white;
}

.iconstyle {
  margin-right: 5px;
}
